import { render, staticRenderFns } from "./ForumSpeakerList.vue?vue&type=template&id=6499e871&scoped=true&"
import script from "./ForumSpeakerList.vue?vue&type=script&lang=js&"
export * from "./ForumSpeakerList.vue?vue&type=script&lang=js&"
import style0 from "./ForumSpeakerList.vue?vue&type=style&index=0&id=6499e871&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6499e871",
  null
  
)

export default component.exports