export default{
    methods: {
        parseTime(start, end) {
            // Вытаскиваем время начала и окончания программы из timestamp
            const startDate = new Date(start);
            const endDate = new Date(end);
            const hhStart = String(startDate.getHours());
            const mmStart = `${startDate.getMinutes() < 10 ? '0' : ''}${startDate.getMinutes()}`;
            const hhEnd = String(endDate.getHours());
            const mmEnd = `${endDate.getMinutes() < 10 ? '0' : ''}${endDate.getMinutes()}`;
            return `${hhStart}:${mmStart} - ${hhEnd}:${mmEnd}`
        }
    }
}