<template>
    <div class="dashboard">
        <router-link :to="{ name: 'forum-details', params: { forumId: getForumId } }" class="back-btn mb-3">
            <b-icon icon="chevron-left"></b-icon>
            <span>назад к деталям ивента</span>
        </router-link>
        <div v-if="forum" class="wrapper mb-4">
            <div class="forum-name">
                <div class="h1">{{forum.title}}</div>
                <div>
                    <router-link :to="{ name: 'forum-edit', params: { forumId: getForumId }, hash: '#eventAnchor' }" class="btn-crl">
                        <b-icon icon="pencil-fill"></b-icon>
                    </router-link>
                </div>
            </div>
            <b-tabs v-if="forum.events.length" card class="scene-tabs">
                <b-tab :title="room.roomName.split(',').join(', ')" v-for="room in groupEventsByRoom(forum.events)" :key="room.roomName">
                    <div class="event-list">
                        <div class="mb-3" v-for="date in groupEventsByDate(room.roomEvents)" :key="date.eventDate">
                            <div class="event-day">{{date.eventDate}}</div>
                            <div v-for="event in date.dateEvents" :key="event._id">
                                <div class="event-item">
                                    <div class="event-time">
                                        {{parseTime(event.timeStart, event.timeEnd)}}
                                    </div>
                                    <div class="event-desc">
                                        <div class="event-title">{{event.title}}</div>
                                        <div class="event-subtitle">{{event.description}}</div>
                                        <forum-speaker-list :speakers="event.speakers"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>

            <div v-else>
                <b-alert show variant="secondary">На данный ивент не заданы программы</b-alert>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import parseTime from '@/mixins/parseTime'
    import parseDate from '@/mixins/parseDate'
    import ForumSpeakerList from '@/components/details/ForumSpeakerList'

    export default {
        name: "program",
        components: {
            ForumSpeakerList
        },
        mixins: [
            parseTime,
            parseDate
        ],
        computed: {
            ...mapGetters([
                'forumByID'
            ]),
            getForumId(){
                return this.$route.params.forumId
            },
        },
        methods: {
            ...mapActions([
                'getForumById',
            ]),
            groupEventsByRoom(events){
                // Определяем кол-во сцен (rooms)
                const roomsName = [];
                events.map(event => {
                    if(!roomsName.includes(event.room)){
                        roomsName.push(event.room);
                    }
                });

                // Групируем ивенты по сценам (rooms)
                return roomsName.map(roomName => {
                    return {
                        roomName,
                        roomEvents: events.filter(event => {
                            return event.room === roomName
                        })
                    }
                });
            },
            groupEventsByDate(events){
                const eventsDate = [];
                const eventsByTimeStart = events.sort((a,b) => {
                    return a.timeStart - b.timeStart
                });
                eventsByTimeStart.map(event => {
                    const day = this.parseDate(event.timeStart);
                    if(!eventsDate.includes(day)){
                        eventsDate.push(day);
                    }
                });

                // Групируем ивенты по дням
                return eventsDate.map(eventDate => {
                    return {
                        eventDate,
                        dateEvents: eventsByTimeStart.filter(event => {
                            return this.parseDate(event.timeStart) === eventDate
                        })
                    }
                });
            }
        },
        created(){
            // Берем из стора информацию о форуме
            this.getForumById(this.getForumId).then(() => {
                this.forum = this.forumByID(this.getForumId);
            });
        },
        data(){
            return {
                forum: null
            }
        }
    }
</script>

<style lang="scss" scoped>
    .forum-name{
        display: flex;
        justify-content: space-between;
    }
    .event{
        &-day{
            color: #4b4b55;
        }
        &-list{
            font-size: 18px;
            padding: 20px 0 0;
        }
        &-item{
            display: flex;
            flex-wrap: nowrap;
            border-bottom: 1px solid rgba(0, 0, 0, 0.08);
            padding: 10px 0;
        }
        &-time{
            width: 190px;
            flex:none;
            font-weight: 600;
            font-size: 16px;
        }
        &-desc{
            flex:1;
            padding: 0 0 0 20px;
        }
        &-subtitle{
            font-size: 14px;
            color: #4b4b55;
            margin-bottom: 12px;
        }
    }
    @media screen and (max-width: 991px) {
        .event{
            &-item{
                flex-direction: column;
            }
            &-time{
                width: 100%;
                margin: 0 0 10px;
            }
        }
    }
</style>

<style lang="scss">
    .scene-tabs{
        .nav-tabs{
            .nav-link{
                max-width: 350px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .scene-tabs{
            .nav-tabs{
                flex-direction: column;
                .nav-item{
                    margin: 0 0 10px;
                }
                .nav-link{
                    max-width: 100%;
                }
            }
        }
    }
</style>